import logo from './logo.svg';
import './App.css';
import Feed from './Post';

function App() {
  return (
   <Feed/>
  );
}

export default App;
