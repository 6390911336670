import React, {Component} from "react";
import "./post.css";


export default class Post extends Component{
    constructor(props) {
        super(props)
        this.state = null
    }

  componentDidMount(){
    fetch('./posts').then((res) => res.json()).then(posts => this.setState(posts));
  }

  render() {
    if (!this.state) {
        return <div />
    }
    const template = [];
    for(let i in this.state) {
        const post = JSON.parse(this.state[i])
        console.log(post.title)
        const temp =  (
            <article className="post">
                <header>
                    <div>
                        <p>{post.username}</p>
                    </div>
                </header>
                <div id ="post__details">
                    <h4>{post.title}</h4>
                    <p>{post.content}</p>
                    {post.img && <img src={post.img} alt="post_image" className="post__image" />}                
                </div>
                <div className="post__footer">
                    <div className="post__reactions">
                        <img src="https://www.svgrepo.com/show/87222/like.svg" alt="like" />
                        <p>300</p>
                    </div>
                </div>
            </article>
        );
        template.push(temp);
    }
    return template;
  }
}

/*
const Post1 = () => {
    let posts = {}};
    posts = JSON.parse(posts.value());
    console.log(posts);
    const template = [];
    for(let i of posts) {
        const post = posts[i]
        const temp =  (
            <article className="post">
                <header>
                    <div>
                        <h3>{post.username}</h3>
                    </div>
                    <div>
                        <p>post.title</p>
                    </div>
                </header>
                <div id ="post__details">
                    <h4>`${post.content}`</h4>
                    {post.img && <img src={post.img} alt="post_image" className="post__image" />}                
                </div>
                <div className="post__footer">
                    <div className="post__reactions">
                        <img src="https://www.svgrepo.com/show/87222/like.svg" alt="like" />
                        <p>300</p>
                    </div>
                </div>
            </article>
        );
        template.push(temp);
    }
   
    return template;
};
*/
// export default Post;